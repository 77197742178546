import React, { useState, useRef, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import '../css/GetPlan.css'

const reviews = [
    {
        name: 'Tony H.',
        text: "I like this app it's far easier to use than some other singing apps. The interface and lessons are actually diverse enough and plenty to actually get a day's work in a lesson. However, one problem...there's this record scratch noise that plays whenever you mess up and for an app teaching you how to sing, they sure love to distract you with this feature. I wish we could silent it somehow or at least implent a vibrate only feature. It's jarring and takes you out of your headspace. Please fix that in an update soon."
    },
    {
        name: 'Kris T.',
        text: "I wanted to learn how to sing and didn’t know where to start. I read great reviews about this app so I downloaded it and have been doing ten minute lessons almost every day for the past year. It is a great resource, especially when you are starting from level 1. I highly recommend!"
    },
    {
        name: 'Den',
        text: "Singbot works for children from something like age six (once they get bored with other education apps:) It is fantasatic to have an app that is available whenever we feel like playing, adapts to your speed, gives immediate feedback and has a ton of songs to choose from. New songs are being added and the experience refined on a regular basis."
    }
]

export default function GetPlan() {
    const history = useHistory()
    const [isStickyShown, setIsStickyShown] = useState(false)
    const getPlanButtonRef = useRef(null)
    const stickyRef = useRef(null)

    // TODO: throttle?
    const handleScroll = useCallback(() => {
        if (!getPlanButtonRef.current) return;

        const shouldStickyPopUp = getPlanButtonRef.current.getBoundingClientRect().bottom < 0;

        if (shouldStickyPopUp && !isStickyShown) {
            setIsStickyShown(true)
        } else if (!shouldStickyPopUp && isStickyShown) {
            setIsStickyShown(false)
        }
    }, [isStickyShown, getPlanButtonRef])

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => { window.removeEventListener('scroll', handleScroll) }
    }, [handleScroll])

    useEffect(() => {
        if (stickyRef.current === null) return
        stickyRef.current.className = isStickyShown ? 'get-plan-sticky' : 'get-plan-sticky hidden';
    }, [isStickyShown, stickyRef])

    const navigateToSignIn = useCallback(() => {
        setTimeout(() => history.push('sign-in'), 200)
    }, [history])

    return (
        <div className='get-plan-container center-align'>
            <div className='get-plan-description-container'>
                <img src='/images/sing@2x.png' srcSet='/images/sing@2x.png 2x' alt='Get Plan' />
                <div className='get-plan-ready'>Your personalized program is ready!</div>
                <div className='get-plan-title'>Step by step guide how to improve your voice</div>
                <button ref={getPlanButtonRef} onClick={navigateToSignIn} className='btn btn-large btn-get-plan waves-effect waves-light'>Get Plan</button>

                <div className='get-plan-divider' />

                <img src='/images/stars@2x.png' srcSet='/images/stars@2x.png 2x' alt='Stars' />

                <div className='get-plan-subtitle'>Masterclass programs</div>
                <img src='/images/getplan1@2x.png' srcSet='/images/getplan1@2x.png 2x' alt='Lessons' />
                <div className='get-plan-text'>The idea is simple: if you can speak, you can sing. We will help you learn the singing basics and train your voice no matter what singing level you have right now</div>

                <div className='get-plan-subtitle'>Accurate pitch tracking</div>
                <img src='/images/getplan2@2x.png' srcSet='/images/getplan2@2x.png 2x' alt='Pitch Tracking' />
                <div className='get-plan-text'>We track your pitch in real time and give instant feedback</div>
            </div>

            <div className='get-plan-reviews-container'>
                <div className='get-plan-reviews-title'>Join thousands of happy users</div>

                <div ref={stickyRef} className='get-plan-sticky hidden'>
                    <button onClick={navigateToSignIn} className='btn btn-large btn-get-plan waves-effect waves-light'>
                        Get Plan
                    </button>
                </div>

                <img src='/images/rating-huge@2x.png' srcSet='/images/rating-huge@2x.png 2x' alt='App Stars' />
                <div className='get-plan-reviews-subtitle'>More than 2k reviews</div>
                {reviews.map((x, i) => (
                    <div className='get-plan-review-container-card' key={i}>
                        <div className='get-plan-review-header'>
                            <span className='get-plan-reviewer-name'>{x.name}</span>
                            <img src='/images/rating-small@2x.png' srcSet='/images/rating-small@2x.png 2x' alt='Review Stars' />
                        </div>
                        <div className='get-plan-review-text'>{x.text}</div>
                    </div>))}
            </div>
        </div>
    )
}
