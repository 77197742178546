import React, { useState, useCallback } from 'react'
import GetPlan from '../components/GetPlan'
import Questionnaire from '../components/Questionnaire'
import GetReady from '../components/GetReady'
import '../css/QuestionnairePage.css'

const questions = [
    {
        title: 'Why are you learning to sing?',
        selecledAnswer: null,
        answers: [
            'Learn singing basics',
            'Improve my vocal skills',
            'Just for fun'
        ]
    },
    {
        title: 'How much singing experience do you have?',
        selectedAnswer: null,
        answers: [
            'None',
            'A little',
            'A lot'
        ]
    },
    {
        title: 'How much time do you want to spend learning?',
        selectedAnswer: null,
        answers: [
            '5 minutes',
            '10 minutes',
            '30 minutes'
        ]
    },
    {
        title: 'Where is your place for practice?',
        selectedAnswer: null,
        answers: [
            'Home',
            'My car',
            'Everywhere'
        ]
    }
]

export default function QuestionnairePage() {
    const [questionnaire, setQuestionnaire] = useState({
        currentQuestionIndex: 0,
        isFinished: false,
        planReady: false
    })

    const nextQuestionHandler = useCallback(() => {
        setQuestionnaire(prev => {
            if (prev.isFinished) {
                return prev
            } else if (prev.currentQuestionIndex === questions.length - 1) {
                return {
                    ...prev,
                    isFinished: true
                }
            } else
                return {
                    ...prev,
                    currentQuestionIndex: prev.currentQuestionIndex + 1
                }
        })
    }, [])

    const planReadyHandler = useCallback(() => {
        setQuestionnaire(prev => {
            if (prev.planReady) return prev
            return {
                ...prev,
                planReady: true
            }
        })
    }, [])

    const getPlanClasses =
        questionnaire.planReady
            ? 'questionnaire-page-get-plan'
            : 'questionnaire-page-get-plan hidden'

    if (questionnaire.isFinished) {
        return (
            <div>
                {
                    !questionnaire.planReady &&
                    <div className='questionnaire-page-get-ready'>
                        <GetReady planReadyHandler={planReadyHandler} />
                    </div>
                }
                <div className={getPlanClasses}>
                    <GetPlan />
                </div>
            </div>
        )
    }

    return (<Questionnaire questionnaire={questionnaire} questions={questions} nextQuestionHandler={nextQuestionHandler} />)
}