import React from 'react'
import SingBotLogoContainer from '../components/SingBotLogoContainer'
import '../css/ThanksPage.css'

export default function ThanksPage() {
    return (
        <SingBotLogoContainer>
            <div className='thanks-text'>Thanks, your subscription is still active!</div>
        </SingBotLogoContainer>
    )
}
