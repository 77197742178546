import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import QuestionnairePage from './pages/QuestionnairePage'
import SignInPage from './pages/SignInPage'
import M from 'materialize-css/dist/js/materialize.min'
import './App.css'
import ThanksPage from './pages/ThanksPage'
import ErrorPage from './pages/ErrorPage'

function App() {
    M.AutoInit()

    return (
        <BrowserRouter>
            <Switch>
                <Route path='/' exact>
                    <QuestionnairePage />
                </Route>
                <Route path='/sign-in' exact>
                    <SignInPage />
                </Route>
                <Route path='/thanks' exact>
                    <ThanksPage />
                </Route>
                <Route path='/error' exact>
                    <ErrorPage />
                </Route>
                <Redirect to='/' />
            </Switch>
        </BrowserRouter>
    );
}

export default App;
