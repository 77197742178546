import React from 'react'
import '../css/Loader.css'

export default function Loader({ show }) {
    return (
        <div className={show ? 'lds-default' : 'lds-default hidden'}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    )
}
