import React from 'react'
import '../css/Question.css'

export default function Question({ currentQuestion, answer, isChecked, selectAnswer }) {
    const cardClassNames = ['answer-card']
    const checkClassNames = ['small material-icons singbot-purple animated']

    if (!isChecked && currentQuestion.selectedAnswer) {
        cardClassNames.push('inactive')
    }

    if (!isChecked) {
        checkClassNames.push('hidden')
    }

    return (
        <div className={cardClassNames.join(' ')} onClick={selectAnswer}>
            <div className='answer-card-content valign-wrapper'>
                <span className='answer-text'>{answer}</span>
                <span>
                    <i className={checkClassNames.join(' ')}>check</i>
                </span>
            </div>
        </div>
    )
}
