import React from 'react'
import logo from '../images/singbot_logo.svg'
import '../css/SingBotLogoContainer.css'

export default function SingBotLogoContainer(props) {
    return (
        <div className='singbot-logo-container hcenter'>
            <img src={logo} alt='SingBot Logo' className='singbot-logo' />
            {props.children}
        </div>
    )
}
