import firebase from 'firebase/app'
import 'firebase/auth'
import axios from 'axios'
import * as firebaseui from 'firebaseui'

let ui = null

export async function startFirebaseUi(
    firebaseuiAuthContainerName,
    handleUiShown,
    handleSignInSuccess,
    handleActiveSubscription,
    handleRedirectToPaymentForm,
    handleError) {

    const res = await axios.get('/api/v1/firebase-client-cfg')
    const firebaseCfg = res.data

    if (firebase.apps.length === 0) {
        firebase.initializeApp(firebaseCfg)
        ui = new firebaseui.auth.AuthUI(firebase.auth())
    }

    if (!ui) return

    ui.start(firebaseuiAuthContainerName, {
        signInFlow: 'popup',
        signInOptions: [
            'apple.com',
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        ],
        callbacks: {
            uiShown: handleUiShown,
            signInSuccessWithAuthResult: (authResult, _) => {
                handleSignInSuccess()

                axios.post(firebaseCfg.signInSuccessRedirectUrl, {
                    user: authResult.user,
                    additionalUserInfo: authResult.additionalUserInfo
                }, { headers: { 'Content-Type': 'application/json' } })
                    .then(res => {
                        if (res.data.result === 'SUBSCRIPTION_STILL_ACTIVE') {
                            handleActiveSubscription()
                        } else if (res.data.result === 'REDIRECT_TO_PAYMENT_FORM') {
                            handleRedirectToPaymentForm(res.data.url)
                        } else {
                            throw new Error('Unexpected sign in result')
                        }
                    }).catch(_ => handleError())

                return false
            }
        }
    })
}
