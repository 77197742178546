import React from 'react'
import SingBotLogoContainer from '../components/SingBotLogoContainer'
import '../css/ErrorPage.css'

export default function ErrorPage() {
    return (
        <SingBotLogoContainer>
            <div className='error-message'>Sorry, looks like something went wrong. Please try again.</div>
        </SingBotLogoContainer>
    )
}
