import React, { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { startFirebaseUi } from '../firebase'
import '../css/SignInPage.css'
import 'firebaseui/dist/firebaseui.css'
import '../css/FirebaseUiOverrides.css'
import SingBotLogoContainer from '../components/SingBotLogoContainer'
import Loader from '../components/Loader'

export default function SignInPage() {
    const history = useHistory()
    const [showLoader, setShowLoader] = useState(true)
    const handleUiShown = useCallback(() => setShowLoader(false), [])
    const handleSignInSuccess = useCallback(() => setShowLoader(true), [])
    const handleActiveSubscription = useCallback(() => history.push('/thanks'), [history])
    const handleRedirectToPaymentForm = useCallback(url => window.location.assign(url), [])
    const handleError = useCallback(() => history.push('/error'), [history])

    useEffect(() => {
        startFirebaseUi(
            '#firebaseui-auth-container',
            handleUiShown,
            handleSignInSuccess,
            handleActiveSubscription,
            handleRedirectToPaymentForm,
            handleError)
    }, [handleUiShown, handleSignInSuccess, handleActiveSubscription, handleRedirectToPaymentForm, handleError])

    return (
        <SingBotLogoContainer>
            <div className='sign-in-title'>Sign In</div>
            <div className='sign-in-firebase-ui-container'>
                <Loader show={showLoader} />
                <div id='firebaseui-auth-container'></div>
            </div>
        </SingBotLogoContainer>
    )
}
