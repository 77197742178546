import React, { useState, useEffect, useRef } from 'react'
import logo from '../images/singbot_logo.svg'
import '../css/GetReady.css'

export default function GetReady({ planReadyHandler }) {
    const containerClasses = 'get-ready-container center-align'
    const contentClasses = 'get-ready-content'
    const [processingPercentage, setProcessingPercentage] = useState(0)
    const [ready, setReady] = useState(false)
    const percentageRef = useRef(0)

    useEffect(() => {
        const id = setInterval(() => {
            setProcessingPercentage(prev => Math.min(prev + 1, 100))

            if (percentageRef.current === 100) {
                clearInterval(id)
                setTimeout(() => setReady(true), 1000)
            }
        }, 35)

        return () => clearInterval(id)
    })

    useEffect(() => {
        percentageRef.current = processingPercentage
    }, [processingPercentage])

    useEffect(() => {
        if (ready) {
            setTimeout(planReadyHandler, 200)
        }
    }, [ready, planReadyHandler])

    return (
        <div className={ready ? `${containerClasses} hidden` : containerClasses}>
            <div className={ready ? `${contentClasses} hidden` : contentClasses}>
                <img src={logo} alt='SingBot Logo' />
                <div className='get-ready-title-1'>Get ready for your</div>
                <div className='get-ready-title-2'>Singing Journey</div>
                <div className='get-ready-percentage'>{processingPercentage}% Processing…</div>
            </div>
        </div>
    )
}
