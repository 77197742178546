import React, { useState, useEffect, useRef, useCallback } from 'react'
import Question from './Question'
import '../css/Questionnaire.css'
import SingBotLogoContainer from './SingBotLogoContainer';

export default function Questionnaire({ questionnaire, questions, nextQuestionHandler }) {
    return (
        <SingBotLogoContainer>
            <QuestionnaireHeader questionnaire={questionnaire} questions={questions} />
            <QuestionnaireBody questionnaire={questionnaire} questions={questions} nextQuestionHandler={nextQuestionHandler} />
        </SingBotLogoContainer>
    )
}

const QuestionnaireHeader = ({ questionnaire, questions }) =>
    <div className='hcenter'>
        <div className='questionnaire-page-counter'>
            {questionnaire.currentQuestionIndex + 1} / {questions.length}
        </div>
    </div>

const QuestionnaireBody = ({ questionnaire, questions, nextQuestionHandler }) => {
    const continueButtonClasses = ['btn btn-large btn-continue waves-effect waves-light hcenter']
    const [currentQuestion, setCurrentQuestion] = useState(questions[questionnaire.currentQuestionIndex])
    const questionBodyRef = useRef(null)

    // Display current question.
    useEffect(() => {
        setCurrentQuestion(questions[questionnaire.currentQuestionIndex])
    }, [questionnaire, questions])

    // Animate current question transition.
    useEffect(() => {
        if (questionBodyRef.current !== null && questionnaire.currentQuestionIndex > 0) {
            questionBodyRef.current.className = 'questionnaire-body hcenter hidden'
            setTimeout(() => questionBodyRef.current.className = 'questionnaire-body hcenter enable-transition-animation', 50)
        }
    }, [questionnaire])

    // Update selected answer.
    const selectAnswerHandler = useCallback(answer => {
        setCurrentQuestion(prev => {
            return {
                ...prev,
                selectedAnswer: answer
            }
        })
    }, [])

    const canContinue = !!currentQuestion.selectedAnswer

    if (!canContinue) {
        continueButtonClasses.push('disabled')
    }

    return (
        <div className='questionnaire-body' ref={questionBodyRef}>
            <div className='question-title hcenter'>{currentQuestion.title}</div>
            <div className='questionnaire-answers-container'>
                {currentQuestion.answers.map((x, i) => {
                    let isChecked = currentQuestion.selectedAnswer === x
                    return (<Question key={i} currentQuestion={currentQuestion} answer={x} isChecked={isChecked} selectAnswer={selectAnswerHandler.bind(null, x)} />)
                })}
            </div>

            <button className={continueButtonClasses.join(' ')} onClick={nextQuestionHandler}>Continue</button>
        </div>
    )
}
